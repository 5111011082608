export default {

  company_name: "CROSS BORDER TRADE INTEGRATED SERVICE CO., LIMITED",
  t0: "首页",
  t1: "完善优质的跨境支付体系，为客户提供合规、专业、可靠的跨境贸易一站式收支解决方案；通过服务商体系与跨境支付体系的融合，为金融机构提供有价值的客户来源，并为平台内客户提供贴身的以跨境供应链金融为特色的跨境综合金融服务。",
  t2: "请输入邮箱号作为注册账号",
  t3: "快速注册",
  t4: "跨境服务商体系",
  t5: "跨境支付体系",
  t6: "跨境贸易供应链金融",
  t7: "跨境收单",
  t8: "支持钱包",
  t9: "产品优势",
  t10: "本地支付",
  t11: "支持10+国家/地区的本地钱包支付",
  t12: "多币种",
  t13: "支持CNY、HKD、KWY、PHP、IDR等10+币种",
  t14: "灵活对接",
  t15: "提供API接口对接同时提供客户端后台方便使用",
  t16: "全球账户",
  t17: "全球收",
  t18: "支持英国、欧盟、美国等主流国家/地区本地收款，全球SWIFT收款",
  t19: "全球付",
  t20: "支持UKFAST、FEDWIRE 、SEPA等主流本地付款方式，全球SWIFT付款",
  t21: "多币种账户",
  t22: "与全球知名银行、机构合作，10+币种可供选择",
  t23: "结汇提现",
  t24: "与境内银行、持牌机构合作，合规申报，最快当日到账",
  t25: "全球购销",
  t26: "专业可靠服务于卖家买家双方的购销平台",
  t27: "关于我们",
  t28: "公司简介",
  t29: "完善优质的跨境支付体系，为客户提供合规、专业、可靠的跨境贸易一站式收支解决方案；通过服务商体系与跨境支付体系的融合，为金融机构提供有价值的客户来源，并为平台内客户提供贴身的以跨境供应链金融为特色的跨境综合金融服务。",
  t30: "企业文化",
  t31: "共情 共生 专业 可靠",
  t32: "企业目标",
  t33: "致力于成为全球最专业最可靠的跨境贸易综合服务平台",
  t34: "目标途径",
  t35: "通过搭建完善、优质的跨境贸易服务商体系，为客户提供专业、可靠的跨境贸易一站式综合服务；通过搭建完善、优质的跨境支付体系，为客户提供合规、专业、可靠的跨境贸易一站式收支解决方案；通过服务商体系与跨境支付体系的融合，为金融机构提供有价值的客户来源，并为平台内客户提供贴身的以跨境供应链金融为特色的跨境综合金融服务。",
  t36: "登陆",
  t37: "注册",
  t38: "市场合作",
  t39: "联系我们",
  t40: "MSO 牌照",
  t41: "牌照编号",
  t42: "去查询",
  t43: "从这里开始吧",
  t44: "隐私政策",
}
